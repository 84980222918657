<template>
  <c-step
      stepperGrpCd="IEI_ISSUE_STEP_CD"
      :currentStepCd="popupParam.issuesStepCd"
      :param="param"
      v-model="issuesStepCd"
      @stepBystep="stepBystep"
      @keySetting="keySetting"
      @stepClick="stepClick"
      @closePopup="closePopup">
    </c-step>
</template>
<script>
import { uid } from 'quasar'
export default {
  name: 'internalExternalIssuesDetail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        issuesId: '',
        issuesStepCd: '',
      }),
    },
  },
  data() {
    return {
      editable: true,
      issuesStepCd: '',
      updateBtnData: {
        title: '',
        flag: false,
        research: '',
      },
    };
  },
  watch: {
    'popupParam.issuesStepCd'() {
      this.setStep();
    },
  },
  computed: {
    param() {
      return {
        issuesId: this.popupParam.issuesId,
        issuesStepCd: this.popupParam.issuesStepCd,
        updateBtnData: this.updateBtnData
      }
    },
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      // url setting
      // code setting
      // list setting
      this.setStep();
    },
    setStep() {
      this.issuesStepCd = this.$_.clone(this.popupParam.issuesStepCd);
    },
    stepBystep(stepCd) {
      this.popupParam.issuesStepCd = stepCd
    },
    keySetting(key) {
      this.popupParam.issuesId = key
    },
    closePopup() {
      this.$emit('closePopup')
    },
    stepClick() {
      this.updateBtnData.research = uid()
    }
  }
};
</script>
  